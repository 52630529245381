$color-primary: #1F1F1F;
$color-secondary: #F3F3F3;
$color-secondary-opaque: #F3F3F380;
$color-tertiary: #E3E0DB;
$color-action: #F7BE78;
$color-error: #FF1F1F;
$color-white: #FFFFFF;
$color-slate-grey: #444446;
$color-grey: #7F7F7F;
$app-bar-height: 100px;
$color-brown: #7A5C39;
$color-blue: #00008B;
$color-red: #8B0000;

.main-container {
    height: 100%;
}

.selection-container {
    padding: 20px;
    font-size: 16px;
    color: $color-primary;
    height: calc(100% - $app-bar-height - 40px);
    background: $color-secondary;

    &.is-mobile {
        padding: 0;
        height: calc(100% - $app-bar-height);

        @media (max-height: 540px) {
            height: calc(100% - 60px);
        }
    }
}

.landing-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    &.is-mobile {
        padding: 20px;
    }
}

.standard-btn {
    min-width: 180px;
    width: fit-content;
}

.logo {
    border: 1px solid $color-slate-grey;
    width: 180px;
    height: 73.5px;
}

.app-bar {
    header {
        height: $app-bar-height;
        padding: 10px;
        justify-content: center;

        .logo {
            margin-right: 10px;
        }

        .app-bar-actions {
            display: flex;
            flex-grow: 1;
        }
    }

    &.is-mobile {

        header {
            .logo {
                width: 100px;
                height: 41px;
            }

            .app-bar-actions {
                flex-wrap: wrap;
            }

            @media (max-height: 540px) {
                height: 60px;

                .MuiToolbar-root {
                    padding: 0;
                }
            }
        }
    }
}

.far-right {
    margin-left: auto;
}

.configurator {
    width: calc(100% - 380px) !important;

    &.is-mobile {
        width: 100% !important;
        height: calc(100% - 120px) !important;

        @media (max-height: 540px) {
            height: 100% !important;
            width: calc(100% - 240px) !important;
        }
    }
}

.configurator-panel {
    position: absolute;
    right: 60px;
    top: calc($app-bar-height + 120px);
    width: 300px;
    height: 400px;
    background: $color-white;
    border: 2px solid $color-grey;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &.is-mobile {
        right: 20px;
        top: unset;
        bottom: 10px;
        width: calc(100% - 40px);
        height: fit-content;

        .tab-container {
            height: 30px;
            margin-top: -30px;
        }

        .tab {
            svg {
                height: 24px;
            }
        }

        .tab-content {
            padding: 10px;
            width: calc(100% - 20px);
            min-height: 52px;

            @media (max-height: 540px) {
                height: calc(100% - 20px);
                width: 200px;
            }

        }

        .configurator-panel-tab-content {
            display: flex;
            flex-direction: row;

            .toggle-button-group {
                max-width: 70px;

                button {
                    padding: 0 5px;
                }
            }

            .slider-container {
                margin: 0 0 0 10px;
                width: calc(100% - 80px);

                &.depth {
                    width: 100%;
                }

                .MuiStack-root {
                    margin: 0;
                }

                .MuiSlider-root {
                    padding: 2px 0;
                }

                p {
                    margin: 0;
                }
            }
        }

        @media (max-height: 540px) {
            bottom: 20px;
            right: 10px;
            height: calc(100% - 140px);
            width: fit-content;

            .configurator-panel-tab-content {
                flex-direction: column;
                align-items: center;
                gap: 20px;
                margin-top: 10px;

                .toggle-button-group {
                    //flex-direction: row;
                }

                .slider-container {
                    width: 100%;
                }
            }
        }

    }

    .tab-container {
        position: absolute;
        margin-top: -60px;
        margin-left: -2px;
        height: 60px;
        width: calc(100% + 2px);
        display: flex;
        justify-content: space-between;
        background: $color-secondary;
    }

    .tab {
        display: flex;
        flex-grow: 0.25;
        justify-content: center;
        align-items: center;
        background: $color-tertiary;
        border: 2px solid $color-grey;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-right: -2px;

        &.active {
            background: $color-white;
            border-bottom: none;
        }

        //&.left {
        //    border-bottom-left-radius: 10px;
        //}
        //
        //&.right {
        //    border-bottom-right-radius: 10px;
        //}
    }

    .tab-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        width: calc(100% - 2 * 30px);
        height: calc(100% - 2 * 30px);
    }
}

.slider-container {
    margin: 20px 0 50px;

    .MuiSlider-thumb {
        background: $color-white;
        border: 3px solid $color-primary;

        &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
            box-shadow: 0 3px 1px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.3), 0 0 0 1px rgba(0,0,0,0.02);

            @media (hover: none) {
                box-shadow: none;
            }
        }
    }

    .MuiSlider-rail {
        opacity: 1;
        background: $color-primary;
        height: 2.5px;
        border: none;
    }

    .MuiSvgIcon-root {
        font-size: 27px;

        &:hover {
            background: $color-tertiary;
            border-radius: 50%;
        }
    }
}

.color-selection-container {
    width: 100%;
    border-radius: 5px;

    &.is-mobile {
        position: absolute;
        right: 10px;
        width: 40px;
        bottom: 130px;
        background: $color-secondary-opaque;

        @media (max-height: 540px) {
            bottom: 10px;
            left: 10px;
            height: 40px;
            width: fit-content;

            //.color-selection {
            //    flex-direction: row;
            //}

            .MuiTypography-root {
                visibility: hidden;
            }
        }

        .color-selection {
            gap: 5px;
        }
    }

    .color-selection {
        justify-content: space-evenly;
        align-items: center;
        width: 100%;

        .MuiFormControlLabel-root {
            margin: 0;
        }

        .MuiRadio-root {
            &.dark {
                color: $color-brown;
            }
            &.light {
                color: $color-action;
            }
            &.blue {
                color: $color-blue;
            }
            &.red {
                color: $color-red;
            }
        }
    }
}